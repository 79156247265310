
export const getDate = (date: any) => {
    if (date !== null) {
      let parsedDate = new Date(Date.parse(date));
      return (
        parsedDate.getUTCDate() +
        "." +
        (parsedDate.getMonth() + 1) +
        "." +
        parsedDate.getFullYear()
      );
    }
    return "";
  };