import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import EmployeesService from "../services/EmployeesService";
import "../users/Users.scss";

const CreateUser = (props: any) => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [selectedRoles, setSelectedRoles] = useState();

  const handleSubmitUser = async (e: any) => {
    e.preventDefault();
    var response = await new EmployeesService().addEmployee(
      firstName,
      lastName,
      email,
      selectedRoles
    );
    if (response !== undefined && response.status === 200) {
      var confirmation = await new EmployeesService().sendConfirmation(
        response.data.id
      );
      if (confirmation.status === 200) {
        props.addUserResult("New user added successfully");
      } else {
        props.addUserResult("Error, confirmation email not sent");
      }
    } else {
      props.addUserResult("Error adding new user, please try again");
    }
  };

  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleRolesChange = (e: any) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedRoles(value);
  };

  const showRoles = () => {
    var rolesList = [];
    if (props.roles !== undefined) {
      for (let index = 0; index < props.roles?.length; index++) {
        rolesList.push(
          <option key={`role${index}`}>{props.roles[index]}</option>
        );
      }
    }
    return rolesList;
  };

  return (
    <Form onSubmit={handleSubmitUser} className={`${props.visibility}`}>
      <Row>
        <Col md="4">
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="John"
              onChange={handleFirstNameChange}
            />
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Smith"
              onChange={handleLastNameChange}
            />
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              onChange={handleEmailChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="roles">
        <Form.Label>Select roles</Form.Label>
        <Form.Control
          required
          as="select"
          multiple
          onChange={handleRolesChange}
        >
          {showRoles()}
        </Form.Control>
      </Form.Group>
      <Button type="submit">Create user</Button>
    </Form>
  );
};

export default CreateUser;
