import React, { useEffect, useState } from "react";
import NavBar from "../Navbar";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Alert,
} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { IProjectInfo } from "../interfaces/IProjectInfo";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import ProjectInfoService from "../services/ProjectInfoService";
import "./ProjectInfoList.scss";
import CreateProjectInfo from "./CreateProjectInfo";
import UpdateProjectInfo from "./UpdateProjectInfo";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

const ProjectInfoList = () => {
  let { id, projectId } = useParams();
  let baseUrl = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProjectInfo, setSelectedProjectInfo] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [language, setLanguage] = useState("en");
  const [allProjectInfo, setAllProjectInfo] = useState<
    [IProjectInfo] | undefined
  >();
  const [addProjectInfoResult, setAddProjectInfoResult] = useState<
    string | undefined
  >();
  const [deleteResult, setDeleteResult] = useState<string | undefined>();
  const [showCreateProjectInfo, setShowCreateProjectInfo] = useState(false);
  const [showUpdateProjectInfo, setShowUpdateProjectInfo] = useState(false);
  const [updateProjectInfoResult, setUpdateProjectInfoResult] = useState<
    string | undefined
  >();
  let addProjectInfoResultVisibility = addProjectInfoResult ? "" : "hidden";
  let createProjectInfoVisibility = addProjectInfoResult ? "hidden" : "";
  let deleteResultVisibility = deleteResult ? "" : "hidden";
  let deleteButtonsVisibility = deleteResult ? "hidden" : "";
  let updateProjectInfoResultVisibility = updateProjectInfoResult
    ? ""
    : "hidden";
  let updateProjectInfoVisibility = updateProjectInfoResult ? "hidden" : "";

  const handleShowUpdateProjectInfo = (index: any) => {
    setSelectedProjectInfo(index);
    setShowUpdateProjectInfo(true);
  };

  const handleUpdateProjectInfoResult = (message: string) => {
    setUpdateProjectInfoResult(message);
  };

  const handleCloseUpdateProjectInfo = () => {
    setUpdateProjectInfoResult(undefined);
    setShowUpdateProjectInfo(false);
    window.location.reload();
  };

  const showDeleteProjectInfo = (index: any) => {
    setSelectedProjectInfo(index);
    handleShowConfirmation();
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    window.location.reload();
  };

  const handleShowConfirmation = () => setShowConfirmation(true);

  useEffect(() => {
    const setProjectData = async () => {
      try {
        var projectInfo = await new ProjectInfoService().getProjectInfos(
          projectId,
          currentPage,
          language
        );
        if (projectInfo === undefined) {
          history.replace("/");
        } else if (projectInfo.length) {
          setAllProjectInfo(projectInfo);
        } else if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          history.replace("/");
        }
      }
    };
    setProjectData().catch((e) => console.log(e));
  }, [currentPage, language, history, projectId]);

  const goPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value);
  };

  const getProjectInfo = () => {
    var projectInfoList = [];
    if (allProjectInfo !== undefined) {
      for (let index = 0; index < allProjectInfo?.length; index++) {
        projectInfoList.push(
          <tr key={`tr${index}`}>
            <td key={`text${index}`} style={{whiteSpace: "pre-wrap"}}>{allProjectInfo[index].text}</td>
            <td key={`img${index}`}>
              <img
                alt=""
                src={baseUrl + "/" + allProjectInfo[index].imageUrl}
                className="thumbnail"
              />
            </td>
            <td className="middleAlign" key={`del${index}`}>
              <IconButton
                color="secondary"
                onClick={(e: any) => showDeleteProjectInfo(index)}
              >
                <RemoveIcon></RemoveIcon>
              </IconButton>
            </td>
            <td className="middleAlign" key={`upd${index}`}>
              <IconButton
                onClick={(e: any) => handleShowUpdateProjectInfo(index)}
              >
                <EditIcon></EditIcon>
              </IconButton>
            </td>
          </tr>
        );
      }
    }
    return projectInfoList;
  };

  const handleShowCreateProjectInfo = () => {
    setShowCreateProjectInfo(true);
  };

  const handleAddProjectInfoResult = (message: string) => {
    setAddProjectInfoResult(message);
  };

  const handleCloseCreateProjectInfo = () => {
    setAddProjectInfoResult(undefined);
    setShowCreateProjectInfo(false);
    window.location.reload();
  };

  const deleteProjectInfo = async (e: any) => {
    e.preventDefault();
    if (allProjectInfo !== undefined) {
      var projectId: string = allProjectInfo[selectedProjectInfo].id;
      var response: boolean = await new ProjectInfoService().deleteProjectInfo(
        projectId
      );
      if (response === true) {
        setDeleteResult("Project news deleted successfully");
      } else {
        setDeleteResult("Error deleting project news, please try again");
      }
    }
  };

  return (
    <div>
      <NavBar userId={id} />
      <Container fluid className="user-body">
        <Row>
          <Col xl="10" className="center-table">
            <Card>
              <Card.Body>
                <Card.Title>
                  <h3 className="users-title">News</h3>
                  <Fab
                    color="primary"
                    className="button-submit add-button"
                    onClick={() => handleShowCreateProjectInfo()}
                  >
                    <AddIcon />
                  </Fab>
                  <div className="languages">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="en"
                          checked={language === "en"}
                          onChange={(e) => handleLanguageChange(e)}
                        />
                        en
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="fi"
                          checked={language === "fi"}
                          onChange={(e) => handleLanguageChange(e)}
                        />
                        fi
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="sv"
                          checked={language === "sv"}
                          onChange={(e) => handleLanguageChange(e)}
                        />
                        sv
                      </label>
                    </div>
                  </div>
                </Card.Title>
                <Table responsive striped borderless>
                  <thead>
                    <tr>
                      <th>Text</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>{getProjectInfo()}</tbody>
                </Table>
                <div className="page-buttons">
                  <IconButton onClick={(e: any) => goPrevious()}>
                    <ArrowBackIosIcon></ArrowBackIosIcon>
                  </IconButton>
                  <IconButton onClick={(e: any) => goNext()}>
                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                  </IconButton>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Dialog
          open={showCreateProjectInfo}
          onClose={handleCloseCreateProjectInfo}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle className={`${createProjectInfoVisibility}`}>Add news to project</DialogTitle>
          <DialogContent>
            <CreateProjectInfo
              projectId={projectId}
              addProjectInfoResult={handleAddProjectInfoResult}
              visibility={`${createProjectInfoVisibility}`}
            />
          </DialogContent>
          <Alert
            variant="primary"
            className={`${addProjectInfoResultVisibility}`}
          >
            {addProjectInfoResult}
          </Alert>
        </Dialog>
        <Dialog
          open={showConfirmation}
          onClose={handleCloseConfirmation}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle className={`${deleteButtonsVisibility}`}>
            Are you sure you want to delete project news "
            {allProjectInfo !== undefined
              ? ` ${allProjectInfo[selectedProjectInfo].text}`
              : ""}
            "?
          </DialogTitle>
          <DialogContent>
            <div className={`${deleteButtonsVisibility} delete-buttons`}>
              <Button
                type="submit"
                variant="secondary"
                onClick={deleteProjectInfo}
                className={` yes-button`}
              >
                Yes
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={handleCloseConfirmation}
              >
                No
              </Button>
            </div>
            <Alert variant="primary" className={`${deleteResultVisibility}`}>
              {deleteResult}
            </Alert>
          </DialogContent>
        </Dialog>
        <Dialog
          open={showUpdateProjectInfo}
          onClose={handleCloseUpdateProjectInfo}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle className={`${updateProjectInfoVisibility}`}>
            Update project news "
            {allProjectInfo !== undefined
              ? ` ${allProjectInfo[selectedProjectInfo].text}`
              : ""}
            "
          </DialogTitle>
          <DialogContent>
            <UpdateProjectInfo
              updateProjectInfoResult={handleUpdateProjectInfoResult}
              visibility={`${updateProjectInfoVisibility}`}
              projectInfoSelected={
                allProjectInfo !== undefined
                  ? allProjectInfo[selectedProjectInfo]
                  : ""
              }
              projectId={projectId}
            />
          </DialogContent>
          <Alert
            variant="primary"
            className={`${updateProjectInfoResultVisibility}`}
          >
            {updateProjectInfoResult}
          </Alert>
        </Dialog>
      </Container>
    </div>
  );
};

export default ProjectInfoList;
