import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import "./Login.scss";
import { useHistory } from "react-router-dom";
import UserService from "../services/UserService";
import { IUserAuthentication } from "../interfaces/IUserAuthentication";

const Login = (props: any) => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  let loginErrorVisibility = errorMessages ? "" : "hidden";

  const createErrorMessages = () => {
    return "Invalid credentials";
  };

  const login = async (e: any) => {
    e.preventDefault();
    try {
      await authenticate();
      if (localStorage.getItem("accessToken")) {
        const user = await new UserService().getCurrentUser();

        if (user.roles.includes("Administrator")) {
          history.replace(`/admin/${user.id}/users`);
        } else {
          setErrorMessages(createErrorMessages());
        }
      }
    } catch (error) {
      setErrorMessages(createErrorMessages());
    }
  };

  const authenticate = async () => {
    var userAuth: IUserAuthentication = {
      username,
      password,
    };
    await new UserService().authenticate(userAuth);
  };

  const updateUsername = (event: any) => {
    setUsername(event.target.value);
  };

  const updatePassword = (event: any) => {
    setPassword(event.target.value);
  };

  return (
    <Container className="login-container" component="main" maxWidth="xs">
      <div>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={updateUsername}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={updatePassword}
          />
          <Button
            onClick={(e) => login(e)}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        </form>
        <Alert
          variant={"danger"}
          className={`error-alert ${loginErrorVisibility}`}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorMessages}
        </Alert>
      </div>
    </Container>
  );
};

export default Login;
