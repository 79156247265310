import React, { useState, useEffect } from "react";
import { Form, Alert, Button } from "react-bootstrap";
import ProjectInfoService from "../services/ProjectInfoService";

const CreateProjectInfo = (props: any) => {
  const [image, setImage] = useState<any>();
  const [uploadImageResult, setUploadImageResult] = useState<
    string | undefined
  >();
  const [imageId, setImageId] = useState<any>();
  const [text, setText] = useState<any>({
    sv: "",
    fi: "",
    en: "",
  });
  let imageResultVisibility = uploadImageResult ? "" : "hidden";

  useEffect(() => {
    if (image !== undefined) {
      const uploadImage = async () => {
        try {
          var response = await new ProjectInfoService().uploadImage(image);
          if (response.status === 200) {
            setImageId(response.data.id);
            setUploadImageResult("Image uploaded successfully");
          }
        } catch (error) {
          setUploadImageResult("Error uploading image, please try again");
        }
      };
      uploadImage().catch((e) => console.log(e));
    }
  }, [image]);

  const handleSubmitProjectInfo = async (e: any) => {
    e.preventDefault();
    var response = await new ProjectInfoService().addProjectInfo(
      props.projectId,
      text,
      imageId
    );
    if (response !== undefined && response.status === 200) {
      props.addProjectInfoResult("New project info added successfully");
    } else {
      props.addProjectInfoResult(
        "Error, project info not created please try again"
      );
    }
  };

  const handleTextChange = (e: any, lang: any) => {
    const updatedText = { ...text };
    if (lang === "fi") {
      updatedText.fi = e.target.value;
      setText(updatedText);
    } else if (lang === "sv") {
      updatedText.sv = e.target.value;
      setText(updatedText);
    } else {
      updatedText.en = e.target.value;
      setText(updatedText);
    }
  };

  const saveImage = (e: any) => {
    setImage(e.target.files[0]);
  };

  return (
    <Form onSubmit={handleSubmitProjectInfo} className={`${props.visibility}`}>
      <h5>Text</h5>
      <Form.Group controlId="description-en">
        <Form.Label>English</Form.Label>
        <Form.Control
          required
          type="text"
          as="textarea"
          placeholder="Text in English"
          onChange={(e) => handleTextChange(e, "en")}
        />
      </Form.Group>
      <Form.Group controlId="description-fi">
        <Form.Label>Finnish</Form.Label>
        <Form.Control
          required
          type="text"
          as="textarea"
          placeholder="Text in Finnish"
          onChange={(e) => handleTextChange(e, "fi")}
        />
      </Form.Group>
      <Form.Group controlId="description-sv">
        <Form.Label>Swedish</Form.Label>
        <Form.Control
          required
          type="text"
          as="textarea"
          placeholder="Text in Swedish"
          onChange={(e) => handleTextChange(e, "sv")}
        />
      </Form.Group>
      <h5>Image</h5>
      <div id="filesubmit" className="float">
        <input
          required
          type="file"
          className="file-select"
          accept="image/*"
          onChange={saveImage}
        />
      </div>
      <Alert variant="info" className={`${imageResultVisibility}`}>
        {uploadImageResult}
      </Alert>
      <Button id="create-project-btn" type="submit">
        Create project news
      </Button>
    </Form>
  );
};

export default CreateProjectInfo;
