import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import "./Projects.scss";
import ProjectsService from "../services/ProjectsService";
import { KeyboardDatePicker } from "@material-ui/pickers";

const CreateProject = (props: any) => {
  const [image, setImage] = useState<any>();
  const [uploadImageResult, setUploadImageResult] = useState<
    string | undefined
  >();
  const [imageId, setImageId] = useState<any>();
  const [title, setTitle] = useState<any>({ sv: "", fi: "", en: "" });
  const [description, setDescription] = useState<any>({
    sv: "",
    fi: "",
    en: "",
  });
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  let imageResultVisibility = uploadImageResult ? "" : "hidden";

  useEffect(() => {
    if (image !== undefined) {
      const uploadImage = async () => {
        try {
          var response = await new ProjectsService().uploadImage(image);
          if (response !== undefined && response.status === 200) {
            setImageId(response.data.id);
            setUploadImageResult("Image uploaded successfully");
          }
        } catch (error) {
          setUploadImageResult("Error uploading image, please try again");
        }
      };
      uploadImage().catch((e) => console.log(e));
    }
  }, [image]);

  const handleSubmitProject = async (e: any) => {
    e.preventDefault();
    var response = await new ProjectsService().addProject(
      title,
      description,
      startDate,
      endDate,
      imageId
    );
    if (response.status === 200) {
      props.addProjectResult("New project added successfully");
    } else {
      props.addProjectResult("Error, project not created please try again");
    }
  };

  const handleTitleChange = (e: any, lang: any) => {
    const updatedTitle = { ...title };
    if (lang === "fi") {
      updatedTitle.fi = e.target.value;
      setTitle(updatedTitle);
    } else if (lang === "sv") {
      updatedTitle.sv = e.target.value;
      setTitle(updatedTitle);
    } else {
      updatedTitle.en = e.target.value;
      setTitle(updatedTitle);
    }
  };

  const handleDescriptionChange = (e: any, lang: any) => {
    const updatedDescription = { ...description };
    if (lang === "fi") {
      updatedDescription.fi = e.target.value;
      setDescription(updatedDescription);
    } else if (lang === "sv") {
      updatedDescription.sv = e.target.value;
      setDescription(updatedDescription);
    } else {
      updatedDescription.en = e.target.value;
      setDescription(updatedDescription);
    }
  };

  const saveImage = (e: any) => {
    setImage(e.target.files[0]);
  };

  const handleStartDateChange = (e: any) => {
    setStartDate(e);
  };

  const handleEndDateChange = (e: any) => {
    setEndDate(e);
  };

  return (
    <Form onSubmit={handleSubmitProject} className={`${props.visibility}`}>
      <h5>Title</h5>
      <Row>
        <Col md="4">
          <Form.Group controlId="title-en">
            <Form.Label>English</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Title in English"
              onChange={(e) => handleTitleChange(e, "en")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="title-fi">
            <Form.Label>Finnish</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Title in Finnish"
              onChange={(e) => handleTitleChange(e, "fi")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="title-sv">
            <Form.Label>Swedish</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Title in Swedish"
              onChange={(e) => handleTitleChange(e, "sv")}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5>Description</h5>
      <Row>
        <Col md="4">
          <Form.Group controlId="description-en">
            <Form.Label>English</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              placeholder="Description in English"
              onChange={(e) => handleDescriptionChange(e, "en")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="description-fi">
            <Form.Label>Finnish</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              placeholder="Description in Finnish"
              onChange={(e) => handleDescriptionChange(e, "fi")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="description-sv">
            <Form.Label>Swedish</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              placeholder="Description in Swedish"
              onChange={(e) => handleDescriptionChange(e, "sv")}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5>Dates</h5>
      <Row>
        <Col md="4">
          <div className="date-start">
            <KeyboardDatePicker
              required
              id="start-date"
              label="Start date"
              value={startDate}
              format="DD/MM/yyyy"
              onChange={(e) => handleStartDateChange(e)}
            />
          </div>
        </Col>
        <Col md="4">
          <div className="date-end">
            <KeyboardDatePicker
              required
              id="end-date"
              label="End date"
              value={endDate}
              format="DD/MM/yyyy"
              onChange={(e) => handleEndDateChange(e)}
            />
          </div>
        </Col>
      </Row>
      <h5>Image</h5>
      <div id="filesubmit" className="float">
        <input
          required
          type="file"
          className="file-select"
          accept="image/*"
          onChange={saveImage}
        />
      </div>
      <Alert variant="info" className={`${imageResultVisibility}`}>
        {uploadImageResult}
      </Alert>
      <Button id="create-project-btn" type="submit">
        Create project
      </Button>
    </Form>
  );
};

export default CreateProject;
