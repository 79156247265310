import axios from "axios";
import { IUser } from "../interfaces/IUser";

class EmployeesService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
  }
  baseUrl: any;
  result: any;

  async getEmployees(currentPage: any): Promise<[IUser]> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let users: any;

    const response = await axios.get(`${this.baseUrl}/employees?Page=${currentPage}&PageSize=10`, config);
    users = response.data;

    return users;
  }

  async addEmployee(
    firstName: string,
    lastName: string,
    email: string,
    roles: [string]
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let createResult: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/employees`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          roles: roles,
        },
        config
      );
      createResult = response;
    } catch (error) {
      console.log(error);
    }

    return createResult;
  }

  async deleteEmployee(id: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let deleteResult: any;

    try {
      const response = await axios.delete(
        `${this.baseUrl}/employees?id=${id}`,
        config
      );
      deleteResult = response.status;
    } catch (error) {
      console.log(error);
    }

    var result = false;
    if (deleteResult === 200) {
      result = true;
    }
    return result;
  }

  async updateEmployee(
    id: any,
    firstName: string,
    lastName: string,
    email: string,
    roles: [string]
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let createResult: any;

    try {
      const response = await axios.put(
        `${this.baseUrl}/employees?id=${id}`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          roles: roles,
        },
        config
      );
      createResult = response.status;
    } catch (error) {
      console.log(error);
    }

    var result = false;
    if (createResult === 200) {
      result = true;
    }
    return result;
  }

  async sendConfirmation(employeeId: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };
    let confirmation: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/employees/sendconfirmation`,
        {
          employeeId: employeeId,
        },
        config
      );
      confirmation = response;
    } catch (error) {
      console.log(error);
    }
    return confirmation;
  }
}

export default EmployeesService;
