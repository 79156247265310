import React from "react";
import { Container, Button, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import "../users/Confirmation.scss";

const Confirmation = () => {
  const history = useHistory();
  let { admin } = useParams();

  const goToLogin = (e: any) => {
    e.preventDefault();
    history.replace(`/`);
  };

  const getBody = () => {
    if (admin === "true") {
      return (<Card.Body>
        You can log in to your account:
        <Button id="goLoginButton" onClick={goToLogin}>Go to login page</Button>
      </Card.Body>);
    } else {
      return (<Card.Body>
        You may now login to the Wasa Dredging mobile app.
        App available in Google Play/AppStore.
      </Card.Body>);
    }
  };

  return (
    <Container fluid="md" id="password-set">
      <Card className="mb-2 confirmation-card">
        <Card.Title>
          Password set successfully
        </Card.Title>
        {getBody()}
      </Card>
    </Container>
  );
};

export default Confirmation;
