import axios from "axios";
import { IProject } from "../interfaces/IProject";

class ProjectsService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
  }
  baseUrl: any;
  result: any;

  async getProjects(
    currentPage: number,
    language: string
  ): Promise<[IProject]> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        "Content-Language": language,
      },
    };

    let projects: any;

    const response = await axios.get(
      `${this.baseUrl}/projects?Page=${currentPage}&PageSize=10`,
      config
    );
    projects = response.data;

    return projects;
  }

  async uploadImage(image: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const formData = new FormData();
    formData.append("image", image);

    let result: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/projects/image`,
        formData,
        config
      );

      result = response;
    } catch (error) {
      console.log(error);
    }

    return result;
  }

  async addProject(
    title: any,
    description: any,
    startDate: any,
    endDate: any,
    projectImageId: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let createResult: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/projects`,
        {
          title: title,
          description: description,
          startDate: startDate,
          endDate: endDate,
          projectImageId: projectImageId,
        },
        config
      );
      createResult = response;
    } catch (error) {
      console.log(error);
    }

    return createResult;
  }

  async deleteProject(id: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let deleteResult: any;

    try {
      const response = await axios.delete(
        `${this.baseUrl}/projects/${id}`,
        config
      );
      deleteResult = response.status;
    } catch (error) {
      console.log(error);
    }

    var result = false;
    if (deleteResult === 200) {
      result = true;
    }
    return result;
  }

  async getProjectAllLanguages(id: string): Promise<any> {
    let projectAllLanguages: any;

    try {
      const projectSv = await this.getProject(id, "sv");
      const projectFi = await this.getProject(id, "fi");
      const projectEn = await this.getProject(id, "en");
      projectAllLanguages = {
        sv: projectSv,
        fi: projectFi,
        en: projectEn,
      };
    } catch (error) {
      console.log(error);
    }

    return projectAllLanguages;
  }

  async getProject(id: string, language: string): Promise<IProject> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        "Content-Language": language,
      },
    };

    let project: any;

    const response = await axios.get(`${this.baseUrl}/projects/${id}`, config);
    project = response.data;

    return project;
  }

  async updateProject(
    id: any,
    title: any,
    description: any,
    startDate: any,
    endDate: any,
    projectImageId: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let updateResult: any;

    try {
      let response: any;
      if (projectImageId !== undefined) {
        response = await axios.put(
          `${this.baseUrl}/projects/${id}`,
          {
            title: title,
            description: description,
            startDate: startDate,
            endDate: endDate,
            projectImageId: projectImageId,
          },
          config
        );
      } else {
        response = await axios.put(
          `${this.baseUrl}/projects/${id}`,
          {
            title: title,
            description: description,
            startDate: startDate,
            endDate: endDate,
          },
          config
        );
      }

      updateResult = response;
    } catch (error) {
      console.log(error);
    }

    return updateResult;
  }
}

export default ProjectsService;
